import { colors, TextStyles } from 'fitify-ui'
import styled from 'styled-components'

import { NAVIGATION_HEIGHT } from '../../Navigation/Navigation.Styled'

export const StyledMobileAppFeatures = styled.div`
  position: relative;
  flex-direction: column;
`

export const StyledMobileAppFeaturesAnchor = styled.div`
  position: absolute;
  top: -${NAVIGATION_HEIGHT}px;
  width: 1px;
  height: 1px;
`

export const StyledMobileAppFeature = styled.div`
  &:not(:first-child) {
    margin-top: 80px;
  }

  align-items: center;
  justify-items: center;
  text-align: center;

  ${TextStyles} {
    text-align: left;
    b {
      color: ${colors.Black};
    }
  }
`
