import { colors, Text } from 'fitify-ui'
import { useTranslation } from 'next-i18next'
import { ReactNode } from 'react'

import { StyledTool, StyledToolIconWrapper } from './Tools.Styled'

const Tool = ({ icon, title }: { icon: ReactNode; title: string }) => {
  const { t } = useTranslation()

  return (
    <StyledTool>
      <StyledToolIconWrapper>{icon}</StyledToolIconWrapper>
      <Text
        as={'span'}
        variant={'review-content'}
        style={{
          textAlign: 'center',
          color: colors.Gray800,
          marginBottom: 'auto',
        }}
      >
        {t(title)}
      </Text>
    </StyledTool>
  )
}

export default Tool
