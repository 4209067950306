import { Text } from 'fitify-ui'
import { StarIcon } from 'fitify-ui/src/Icon/fill/StarIcon'
import { DataTypes } from 'fitify-ui-landing/src/@types'
import { useTranslation } from 'next-i18next'

import { StyledReview, StyledReviewStars } from './Reviews.Styled'

const Review = ({ name, job, description }: DataTypes.ReviewItem) => {
  const { t } = useTranslation()

  return (
    <StyledReview>
      <Text as={'h3'} variant={'newsletter-title'}>
        {t(name)}
      </Text>
      {job && (
        <Text as={'p'} variant={'reviews-job'} style={{ marginTop: '4px' }}>
          {t(job)}
        </Text>
      )}
      <StyledReviewStars>
        <StarIcon color="Yellow400" />
        <StarIcon color="Yellow400" />
        <StarIcon color="Yellow400" />
        <StarIcon color="Yellow400" />
        <StarIcon color="Yellow400" />
      </StyledReviewStars>
      <Text as={'p'} variant={'review-content'}>
        {t(description)}
      </Text>
    </StyledReview>
  )
}

export default Review
