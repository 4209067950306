import { ImageTypes } from 'fitify-ui-landing/src/@types'
import Head from 'next/head'

export const HeaderImagesPrefetch = ({
  images,
}: {
  images: ImageTypes.Header[]
}) => {
  return (
    <Head>
      {images.map((image) => (
        <link
          key={image.headerImage}
          rel="preload"
          as="image"
          href={image.headerImage}
          imageSrcSet={image.headerImageSet}
        />
      ))}
    </Head>
  )
}
