import {
  BarbellIcon,
  BosuIcon,
  DumbbellIcon,
  FoamRollerIcon,
  KettlebellIcon,
  LatStationIcon,
  MedicineBallIcon,
  PullUpBarIcon,
  SandbagIcon,
  SwissBallIcon,
  TrxIcon,
} from 'fitify-ui/src/Icon/fill'
import { ResistanceBandIcon } from 'fitify-ui/src/Icon/outline'
import { DataTypes } from 'fitify-ui-landing/src/@types'

const toolsData: DataTypes.ToolItem[] = [
  {
    icon: <KettlebellIcon />,
    title: 'tool_kettlebell',
  },
  {
    icon: <TrxIcon />,
    title: 'tool_trx_v2',
  },
  {
    icon: <BosuIcon />,
    title: 'tool_bosu',
  },
  {
    icon: <ResistanceBandIcon />,
    title: 'tool_resistanceband',
  },
  {
    icon: <FoamRollerIcon />,
    title: 'tool_foamroller',
  },
  {
    icon: <MedicineBallIcon />,
    title: 'tool_medicineball',
  },
  {
    icon: <SwissBallIcon />,
    title: 'tool_swissball',
  },
  {
    icon: <PullUpBarIcon />,
    title: 'tool_pullupbar',
  },
  {
    icon: <DumbbellIcon />,
    title: 'tool_dumbbell',
  },
  {
    icon: <BarbellIcon />,
    title: 'tool_barbell',
  },
  {
    icon: <SandbagIcon />,
    title: 'tool_sandbag',
  },
  {
    icon: <LatStationIcon />,
    title: 'tool_gymmachines',
  },
]

export { toolsData }
